<template>
  <div class="px-1">
    <h4 class="mt-4">
      {{ $getVisibleNames("manual.matriz_1", false, "Matriz 1") }}:
      {{
        egressProfile
          ? egressProfileCycleId.length > 1
            ? String(
                "Progresión de " +
                  $getVisibleNames("mesh.competence", true, "Competencias") +
                  " por " +
                  $getVisibleNames("mesh.cycle", true, "Ciclos Formativos")
              )
            : String($getVisibleNames("mesh.competence", true, "Competencias"))
          : String($getVisibleNames("mesh.competence", true, "Competencias"))
      }}
      <span v-if="career">{{ " de " + career.name }} </span>
    </h4>
    <br />
    <template
      v-if="(institution && institution.show_base_ra) || $debug_change_duoc"
    >
      <b-button
        @click="redirectToMatrix1PerCycle(egress_profile_id)"
        size="sm"
        class="btn-matrix1-per-cycle mb-1 noprint"
      >
        <b-icon icon="box-arrow-up-right" class="mr-1"></b-icon>Vista de
        {{ $getVisibleNames("manual.matriz_1", false, "Matriz 1") }}</b-button
      >
    </template>
    <Matrix1
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
    ></Matrix1>
    <div
      v-if="
        !(
          (institution && institution.internal_use_id == 'duoc_uc') ||
          $debug_change_duoc
        )
      "
      class="mb-5"
    >
      <template v-if="egress_profile_id">
        <EgressProfileCompetenceMention
          style="margin-bottom: 10em"
          v-if="
            profile_competences_mention.filter(
              (x) => x.egress_profile == egress_profile_id
            ).length > 0 || allows_crud
          "
          :allows_crud="allows_crud"
          :egress_profile_id="egress_profile_id"
        ></EgressProfileCompetenceMention>
      </template>
    </div>
    <MeshCustomFab
      class="noprint"
      :egress_profile_id="egress_profile_id"
      :show_matrix1="false"
    ></MeshCustomFab>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
export default {
  name: "Matrix1View",
  components: {
    Matrix1: () => import("./Matrix1"),
    MeshCustomFab: () => import("../MeshCustomFab"),
    EgressProfileCompetenceMention: () =>
      import("@/components/mesh/EgressProfile/EgressProfileCompetenceMention"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      // printing: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      user_position: names.USER_POSITION,
      profile_cycles: names.PROFILE_CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      institution: "getInstitution",
      profile_competences_mention: names.PROFILE_COMPETENCES_MENTION,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    allows_crud() {
      if (
        this.career &&
        this.egressProfile &&
        !this.egressProfile.is_closed &&
        ((this.user_position &&
          [1, 2].includes(this.user_position.position) &&
          [1, 2].includes(this.user.groups[0])) ||
          this.user.is_superuser ||
          this.$hasObjectPermission(
            "mesh.faculty",
            "change",
            this.career.faculty
          ) ||
          this.$hasObjectPermission(
            "mesh.career",
            "change",
            this.egressProfile.career
          ))
      ) {
        const has_permission = this.$hasPermissions(
          "mesh.change_egressprofile"
        );
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else {
          return false;
        }
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
    egressProfileCycleId() {
      if (!this.egress_profile_id) return [];
      let list = [];
      this.profile_cycles.forEach((x) => {
        if (x.egress_profile == this.egress_profile_id) list.push(x.cycle);
      });
      return list;
    },
  },
  methods: {
    redirectToMatrix1PerCycle(egress_profile_id) {
      // this.$router.push({
      // let route_data = this.$router.resolve({
      this.$router.push({
        name: "Matrix1PerCycleView",
        params: { egress_profile_id: egress_profile_id },
      });
      // window.open(route_data.href, "_blank");
    },
  },
  mounted() {
    this.$store.commit(names.MUTATE_PRINT_ORIENTATION, true);
    this.$store.commit(names.MUTATE_PRINT_ZOOM_SCALE, 1);
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
    this.$store.commit(names.RESET_PRINT_ZOOM_SCALE);
    this.$store.commit(names.RESET_PRINT_ORIENTATION);
  },
  created() {
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_RA_BASES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COMPETENCES, this.egress_profile_id);
    this.$store.dispatch(names.FETCH_PROFILE_CYCLES, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((response) => {
        this.$store.commit(
          names.MUTATE_TITLE_OF_THE_PRINT,
          `${this.$getVisibleNames("manual.matriz_1", false, "Matriz 1")} - ${
            response.name
          }`
        );
        this.$store.dispatch(names.FETCH_CAREER, response.career);
      });
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
    this.$store.dispatch(names.FETCH_PROFILE_COMPETENCES_MENTION, {
      egress_profile_id: this.egress_profile_id,
    });
  },
};
</script>

<style scoped>
th {
  background-color: var(--primary-color);
  color: var(--primary-font-color);
}
table,
th,
td {
  border: 1px solid black;
  position: relative;
}
div.float-button {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
}
.btn-matrix1-per-cycle {
  display: flex;
  margin-right: auto;
  margin-left: 0;
}
</style>