var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"px-1"},[_c('h4',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.matriz_1", false, "Matriz 1"))+": "+_vm._s(_vm.egressProfile ? _vm.egressProfileCycleId.length > 1 ? String( "Progresión de " + _vm.$getVisibleNames("mesh.competence", true, "Competencias") + " por " + _vm.$getVisibleNames("mesh.cycle", true, "Ciclos Formativos") ) : String(_vm.$getVisibleNames("mesh.competence", true, "Competencias")) : String(_vm.$getVisibleNames("mesh.competence", true, "Competencias")))+" "),(_vm.career)?_c('span',[_vm._v(_vm._s(" de " + _vm.career.name)+" ")]):_vm._e()]),_c('br'),((_vm.institution && _vm.institution.show_base_ra) || _vm.$debug_change_duoc)?[_c('b-button',{staticClass:"btn-matrix1-per-cycle mb-1 noprint",attrs:{"size":"sm"},on:{"click":function($event){return _vm.redirectToMatrix1PerCycle(_vm.egress_profile_id)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"box-arrow-up-right"}}),_vm._v("Vista de "+_vm._s(_vm.$getVisibleNames("manual.matriz_1", false, "Matriz 1")))],1)]:_vm._e(),_c('Matrix1',{attrs:{"egress_profile_id":_vm.egress_profile_id,"allows_crud":_vm.allows_crud}}),(
      !(
        (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
        _vm.$debug_change_duoc
      )
    )?_c('div',{staticClass:"mb-5"},[(_vm.egress_profile_id)?[(
          _vm.profile_competences_mention.filter(
            (x) => x.egress_profile == _vm.egress_profile_id
          ).length > 0 || _vm.allows_crud
        )?_c('EgressProfileCompetenceMention',{staticStyle:{"margin-bottom":"10em"},attrs:{"allows_crud":_vm.allows_crud,"egress_profile_id":_vm.egress_profile_id}}):_vm._e()]:_vm._e()],2):_vm._e(),_c('MeshCustomFab',{staticClass:"noprint",attrs:{"egress_profile_id":_vm.egress_profile_id,"show_matrix1":false}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }